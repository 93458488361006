// extracted by mini-css-extract-plugin
export var btnDefault = "carousel-module--btn-default--HvnxK";
export var attoiconJobs = "carousel-module--attoicon-jobs--0BmKt";
export var attoiconNotes = "carousel-module--attoicon-notes--CTB6W";
export var attoiconTimesheets = "carousel-module--attoicon-timesheets--BdTj-";
export var attoiconTimesheetsRounded1 = "carousel-module--attoicon-timesheets-rounded1--Ho+f7";
export var attoiconMobileTt = "carousel-module--attoicon-mobile-tt--OzrdW";
export var attoiconNamedSites = "carousel-module--attoicon-named-sites--7i8SR";
export var attoiconProfitableBids = "carousel-module--attoicon-profitable-bids--QevJn";
export var attoiconDepartments = "carousel-module--attoicon-departments--LT9X9";
export var attoiconTimeline = "carousel-module--attoicon-timeline--VhY6R";
export var attoiconFilters = "carousel-module--attoicon-filters--6aH6s";
export var attoiconVerified = "carousel-module--attoicon-verified--jRfaS";
export var attoiconTimezone = "carousel-module--attoicon-timezone--IBHp+";
export var attoiconManualEntries = "carousel-module--attoicon-manual-entries--yg9fC";
export var attoiconJobCosting = "carousel-module--attoicon-job-costing--Ci4Fa";
export var attoiconLocationTracking = "carousel-module--attoicon-location-tracking--MpBK9";
export var attoiconBreak = "carousel-module--attoicon-break--yRG7q";
export var attoiconNightShift = "carousel-module--attoicon-night-shift--9PD9Y";
export var attoiconOvertime = "carousel-module--attoicon-overtime--vjbuY";
export var attoiconTimeTracking1 = "carousel-module--attoicon-time-tracking1--GbWtt";
export var attoiconTimeTracking = "carousel-module--attoicon-time-tracking--IG1Ew";
export var attoiconLocationHistory = "carousel-module--attoicon-location-history--NkDI7";
export var attoiconPrivacy = "carousel-module--attoicon-privacy--Oprmt";
export var attoiconTimeEntryHistory = "carousel-module--attoicon-time-entry-history--l-ogz";
export var attoiconCustomize = "carousel-module--attoicon-customize--LVqmt";
export var attoiconRoundedClock = "carousel-module--attoicon-rounded-clock--sJ7TK";
export var attoiconTimeOff = "carousel-module--attoicon-time-off--qlMbQ";
export var attoiconNamedLocations = "carousel-module--attoicon-named-locations--PwdkD";
export var attoiconSandClock = "carousel-module--attoicon-sand-clock--YfkO-";
export var attoiconBattery = "carousel-module--attoicon-battery--hOo2B";
export var attoiconSupport = "carousel-module--attoicon-support--WudTA";
export var attoiconHelpSupport = "carousel-module--attoicon-help-support--3KPQ8";
export var attoiconWebTt = "carousel-module--attoicon-web-tt--BD3XB";
export var attoiconArchive = "carousel-module--attoicon-archive--VwOit";
export var attoiconEmail = "carousel-module--attoicon-email--AGyrm";
export var attoiconKiosk = "carousel-module--attoicon-kiosk--+9TGl";
export var attoiconShare = "carousel-module--attoicon-share--04+Id";
export var attoiconCrew = "carousel-module--attoicon-crew--LIILe";
export var attoiconTeamActivity = "carousel-module--attoicon-team-activity--LFc6a";
export var attoiconTeam = "carousel-module--attoicon-team--IqUvV";
export var attoiconWages = "carousel-module--attoicon-wages--VGwN4";
export var attoiconNotification = "carousel-module--attoicon-notification--WILiY";
export var attoiconAvatar = "carousel-module--attoicon-avatar--crbmX";
export var attoiconViewMap = "carousel-module--attoicon-view-map--DCy21";
export var attoiconMovementTracking = "carousel-module--attoicon-movement-tracking--ewjmD";
export var attoiconWageEstimates = "carousel-module--attoicon-wage-estimates--pydzP";
export var attoiconWageEstimatesBold = "carousel-module--attoicon-wage-estimates-bold--12ALC";
export var attoiconClose = "carousel-module--attoicon-close--OIday";
export var attoiconPlus = "carousel-module--attoicon-plus--r0K7q";
export var attoiconMinus = "carousel-module--attoicon-minus--GiHYz";
export var attoiconTick = "carousel-module--attoicon-tick--THBow";
export var attoiconArrowLeft = "carousel-module--attoicon-arrow-left--gjTld";
export var attoiconArrowRight = "carousel-module--attoicon-arrow-right--H4dZM";
export var attoiconArrowDown = "carousel-module--attoicon-arrow-down--n2A5f";
export var attoiconArrowUp = "carousel-module--attoicon-arrow-up--d2SBh";
export var attoiconPlay = "carousel-module--attoicon-play---ka8S";
export var attoiconLongArrow = "carousel-module--attoicon-long-arrow--Mi8mY";
export var carouselWrapper = "carousel-module--carouselWrapper--pWvRr";
export var brainhubCarouselItem = "carousel-module--BrainhubCarouselItem--zkQbg";
export var btnWrapper = "carousel-module--btnWrapper--vxfYv";
export var largeStyle = "carousel-module--largeStyle--bYAE9";
export var leftAligned = "carousel-module--leftAligned--mG6EO";
export var pulledLeft = "carousel-module--pulledLeft--wJj6G";
export var rightAligned = "carousel-module--rightAligned--2mBQx";
export var pulledRight = "carousel-module--pulledRight--fx0n6";
export var disabled = "carousel-module--disabled--qmGGm";